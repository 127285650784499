.Setting-form-div{
   padding: 15px;
   
}

.Setting-form-div h4{
    font-size: 17px;
    margin-bottom:20px ;
}


.Setting_inputfield {
    width: 100%;
    max-width: 320px;
    height: 45px;
    padding: 12px;
    border-radius: 12px;
    border: 1.5px solid lightgrey;
    outline: none;
    transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    box-shadow: 0px 0px 20px -18px;
    margin-bottom: 10px;
  }
  
  .Setting_inputfield:hover {
    border: 2px solid lightgrey;
    box-shadow: 0px 0px 20px -17px;
  }
  
  .Setting_inputfield:active {
    transform: scale(0.95);
  }
  
  .Setting_inputfield:focus {
    border: 2px solid grey;
  }


.Button_div{
    display: flex;
    flex-direction: row;
}