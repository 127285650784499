* {
  font-family: 'Poppins';
}


::-webkit-scrollbar {
  width: 10px;
  background: #dfe2e6;

}

::-webkit-scrollbar-track {
  background: #dfe2e6;

}

::-webkit-scrollbar-thumb {
  background: gray
}



.Main_Container {
  width: 100%;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
  margin-top: 15px;
  padding: 20px;
}


.error-msg {
  font-size: 14px;
  color: #dd1717;
  padding-bottom: 15px;
}

.Month_JS {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-template-rows: repeat(5, minmax(0, 2fr));
}


.Calender_Main_Div {
  display: flex;
  flex-direction: column;


}

.Calender_Month_Div {
  display: flex;
  flex: 1 1 0%;
}

.Calender_header {
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: center;
}

.Calender_TodayButton {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: 1.25rem;
  border-radius: 0.25rem;
  border-width: 1px;
}

.Calender_header_Arrow_Button {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  color: #4B5563;
  cursor: pointer;
}

.Calender_header_Month {
  margin-left: 1rem;
  color: #6B7280;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
}

.Day_div {
  display: flex;
  flex-direction: column;
  border: 1px solid #E5E7EB;


}


.Day_header {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.Day_date {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  /* line-height: 1.25rem; */
  font-weight: 500;
}

.Day_CurrentDate {
  padding: 0.25rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;

}

.Day_divEvent {
  flex: 1 1 0%;
  cursor: pointer;
  padding: 15px;
  /* background-color: green; */
}


.CurrentDay {
  background-color: #2563EB;
  color: #ffffff;
  padding-top: 10px;
  width: 40px;
  height: 40px;
  border-radius: 40px;


}




.Event_Main_Div {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;


}

.Event_Model {
  background-color: #ffffff;
  width: 25%;
  border-radius: 0.5rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.Event_header {
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #F3F4F6;
  justify-content: space-between;
  align-items: center;



}

.Event_Container {
  display: grid;
  align-items: flex-end;

}

.Event_Input {
  padding-bottom: 0.5rem;
  padding-top: 0.75rem;
  color: #4B5563;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  width: 100%;
  border-width: 0;
  border-bottom-width: 2px;
  border-color: #E5E7EB;

}

.Event_Color_div {
  display: flex;
  column-gap: 0.5rem;

}
@media (max-width:576px){
  .Main_Container {
    padding: 20px 10px 10px 10px;
  }
}
.clstxtarea{
  width: -webkit-fill-available;
}
.action-btn {
width:110px;
}
.pagination-container {
  display: flex;
  list-style-type: none;

  .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }

    &.selected {
      background-color: rgba(0, 0, 0, 0.08);
    }

    .arrow {
      &::before {
        position: relative;
        /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
        content: '';
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid rgba(0, 0, 0, 0.87);
        border-top: 0.12em solid rgba(0, 0, 0, 0.87);
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
