.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  width: 100%;
}

.styled-table thead tr {
  background-color: red;
  color: #ffffff;
  /* text-align: center; */
}
.styled-table th {
  font-weight: 500;
  font-size: 17px;
}
.styled-table th,
.styled-table td {
  padding: 10px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody td {
  /* text-align: center; */
  font-size: 15px;
  color: #3e3e3e;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr,
.table > :not(:last-child) > :last-child > * {
  border-bottom: 1px solid #009879 !important;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

.Eye_Icon {
  color: blue;
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;
}
.Schedule_Icon {
  color: green;
  font-size: 20px;
  cursor: pointer;
}

.Delete_Icon {
  color: red;
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;
}

.List_details {
  padding: 25px;
}
.list-title h3 {
  color: #000249;
  text-align: center;
  text-decoration: underline;
}
.List_details h5 {
  font-size: 15px;
  color: gray;
  padding-top: 10px;
}

.List_details h4 {
  color: #000249;
  text-align: center;
}

.List_details h6 {
  font-size: 14px;
  color: #000;
}
.Model_Footer {
  display: flex;
  padding: 20px;
  justify-content: flex-end;
  align-content: flex-end;
}

.list-filters {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  justify-content: center;
}
/* .list-filters {
  margin-left: -20px;
} */
.list-search {
  padding: 0px 20px;
}
.search-text-div {
  padding: 10px 0px;
}
.Filter_Icon {
  font-size: 33.5px;
  margin-left: 15px;
  padding: 6px;
  border: 1px solid gray;
  border-radius: 4px;
  cursor: pointer;
}

.Filter_Input {
  max-width: 500px;
  background-color: #fff;
  color: #242424;
  padding: 10px 40px 10px 10px;
  min-height: 34px;
  border-radius: 3px;
  outline: none;
  border: 1px solid gray;
  line-height: 1.15;
  box-shadow: 0px 10px 20px -18px;
  font-size: 15px;
}

.Filter_Input:focus {
  border: 1px solid #5b5fc7;
  border-radius: 4px;
}

.Filter_Input:hover {
  outline: 1px solid lightgrey;
}

.ButtonList_Div {
  justify-content: flex-end;
  display: flex;
  margin-top: 15px;
}

.Filter_Model_Div {
  flex-direction: row;
  display: flex;
}

.Filter_Model_Div h5 {
  font-size: 15px;
}

.Filter_section {
  min-width: 240px;
  min-height: 35px;
  border: 1px solid gray;
  border-radius: 4px;
}
.btn-download {
  text-decoration: none;
  background: #000249;
  color: white;
  padding: 10px 30px;
  border-radius: 3px;
}
.list-download {
  padding-left: 10px;
}
.list-download a:hover {
  color: #fff;
}
.search-btn {
  padding: 10px 30px;
  border: none;
}
.list-wrapper {
  background: #fff;
  padding: 20px;
}
.view-btn {
  width: auto;
}
.List_details input {
  padding: 5px 10px;
}
.search-text-wrapper {
  padding: 0px 10px;
  background: #fff;
  margin-bottom: 10px;
}
.search-text-wrapper input::placeholder {
  font-size: 15px;
  color: #ced4da;
}
.search-text-wrapper .form-control:focus,
.search-text-wrapper .form-select:focus {
  box-shadow: none;
}
.List_header {
  padding-top: 30px;
  padding-bottom: 10px;
}
.List_details input {
  width: 100%;
}
.all-search-text-box label {
  font-weight: 600;
}
.date-time-div {
  display: grid;
}

@media (max-width: 576px) {
  .list-filters {
    display: block;
    padding-left: 12px;
  }
  .search-text-wrapper {
    padding: 0px 0px;
  }
  .list-download {
    padding-left: 0px;
    padding-top: 30px;
  }
  .action-btn {
    margin-left: 0px;
  }
  .Eye_Icon,
  .Schedule_Icon,
  .Delete_Icon {
    margin-left: 0px;
  }
}

@media (max-width: 768) {
  .List_details input {
    width: unset;
  }
}
