@font-face {
  font-family: "Poppins";
  src: url("../src/Assets/Font/Poppins-Regular.ttf");
}

.dataTables_length .form-select:focus,.search-box .form-control:focus {
  outline: none;
  box-shadow: none;
}

.row{
  margin: 0px !important;
  padding: 0px !important;
}
