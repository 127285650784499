.booking-wrapper {
  margin: 0 auto;
  width: 70%;
}

.Booking_Main h3 {
  color: #2c3345;
  font-size: 24px;
  font-weight: 700;
}

.Booking_Main h4 {
  color: #2c3345;
  font-size: 14px;
  margin: 5px;
  font-weight: bold;
}

.Booking_Main h6 {
  padding-left: 5px;
  padding-top: 8px;
  padding-bottom: 10px;
  font-size: 14px;
}

.Booking_Head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Booking_Main input {
  margin-right: 85px;
  border: 1px solid #b8bdc9;
  border-radius: 4px;
  height: 2.2rem;
  width: 300px;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.Custom_Select select {
  margin-right: 90px;
  border: 1px solid #b8bdc9;
  border-radius: 4px;
  padding: 8px 16px;
}
.Form_Div {
  padding: 20px 0px;
}

.button-32 {
  background-color: #180c3c !important;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  padding: 10px 15px;
  text-align: center;
  transition: 200ms;
  width: 100%;
  box-sizing: border-box;
  border: 0;
  font-size: 15px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-top: 30px;
}

.button-32:not(:disabled):hover,
.button-32:not(:disabled):focus {
  outline: 0;
  background: #180c3c;
  /* box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2), 0 3px 8px 0 rgba(0, 0, 0, 0.15); */
}

.button-32:disabled {
  filter: saturate(0.2) opacity(0.5);
  -webkit-filter: saturate(0.2) opacity(0.5);
  cursor: not-allowed;
}

.booking-label {
  color: #575757;
}

.Form_input {
  width: 100%;
  padding: 8px 16px;
  background: transparent;
  border-radius: 4px;
  border: 1px solid #fe4567;
  color: #000;
  animation: shake_541 0.14s 3;
  border: 0.5px solid #b8bdc9 !important;
  margin-bottom: 5px;
  font-size: 15px;
}

.Name_Div input:focus {
  border-color: green !important;
}
.Name_Div input::placeholder {
  color: #b8bdc9;
  font-size: 15px;
}
.Name_Div input:focus-visible,
.Name_Div select:focus-visible,
.Name_Div textarea:focus-visible {
  border: 1px solid green;
  outline: none;
  /* border: none; */
}

@keyframes shake_541 {
  0%,
  100% {
    translate: 0;
  }

  25% {
    translate: 8px 0;
  }

  75% {
    translate: -8px 0;
  }
}

@media (max-width: 768px) {
  .booking-wrapper {
    width: 100%;
  }
  .Form_Div {
    padding: 20px 10px;
  }
}
