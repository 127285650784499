.Login_Container,
.changePwd-section {
  width: 100%;
  height: 100vh;
  background-image: url("../Assets/IMages/loginbg.jpg");
  /* background:linear-gradient(to left top,#060dcf,#ca4a4a) ; */
  background-size: cover;
  background-position: center;
  display: flex;
}
.Login_Container .container {
  padding: 25px 35px 35px 35px;
  border-radius: 10px;
  margin: auto;
}

.login-title {
  padding-top: 10px;
  padding-bottom: 20px;
  font-size: 30px;
  font-weight: 600;

  color: #fff;
  letter-spacing: 0.5px;
}
.logoin-logo-div {
  display: none;
}
.btn-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-form {
  color: #fff;
}
.input-div {
  padding-bottom: 10px;
}
.login-form label {
  display: block;
  margin-bottom: 5px;
  letter-spacing: 0.2px;
}

.login-form input {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: none;
  font-size: 15px;
  padding: 10px;
}

.login-form input:focus {
  outline: none;
}
.login-form input::placeholder {
  color: #aaa9a9;
  font-size: 15px;
}

.btn--form {
  background-color: #d21404;
  color: #fdf2e9;

  padding: 10px 30px;
}
.btn-login {
  margin-top: 0px !important;
  padding: 10px 30px !important;
}
.forgot-pwd-btn {
  padding-top: 10px;
}
.forgot-pwd-btn p a,
.forgot-pwd,
.register-btn p a {
  color: #fff;
  font-size: 15px;
  letter-spacing: 0.2px;
  text-decoration: none;
}
.register-btn {
  padding-top: 5px;
  text-align: center;
  font-size: 20px;
}
.forgot-pwd:hover {
  color: #fff;
}
.register-btn p a:hover {
  color: #fbf537;
}
.register-btn p a {
  padding-left: 5px;
  font-size: 20px;
  color: #fbf537;
  font-weight: 500;
}
.btn,
.btn:link,
.btn:visited {
  display: inline-block;
  text-decoration: none;
  font-size: 17px;
  border-radius: 3px;
  border: none;
  padding: 10px 30px;
  cursor: pointer;
  transition: all 0.3s;
  letter-spacing: 0.2px;
  /* width: 50%; */
}
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}
.btn--form:hover {
  background-color: #d62b1d;
  color: #fff;
}
.page-title {
  position: relative;
}
.Login_Container h3 {
  color: #fff;
  padding-top: 10px;
}
.page-title::after {
  position: absolute;
  content: "";
  bottom: -5px;
  left: 0;
  background-color: #dd1717;
  height: 2px;
  width: 100%;
}
.Login_Logo {
  width: 200px;
  padding: 5px;
  background: #fff;
  border-radius: 3px;
}

.Base_Line {
  color: #fff;
  text-align: end;
  font-size: 14px;
  padding-top: 15px;
  padding-right: 50px;
  cursor: pointer;
}

.btn-wrapper {
  display: flex;
  align-items: baseline;
}
.btn-wrapper {
  padding-top: 20px;
}

.chng-pwd-title {
  color: #fff;
  padding-bottom: 20px;
  text-align: center;
}
.req-errors {
  color: #d21404;
  font-size: 14px;
  margin-bottom: 0px;
}

.form-div-wrapper {
  padding-left: 200px;
  width: 50%;
}

@media (min-width: 767px) and (max-width: 1200px) {
  .form-div-wrapper {
    padding-left: 0px;
    width: 100%;
  }
  .login-form input {
    width: 50%;
  }
  .btn-div {
    display: block;
  }
  .register-btn {
    text-align: left;
  }
  .page-title::after {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .login-form input {
    width: 100%;
  }
  .form-div-wrapper {
    padding-left: 0px;
    width: 100%;
  }
  .login-form input {
    width: 50%;
  }
  .btn-div {
    display: block;
  }
  .register-btn {
    text-align: left;
  }
  .Login_Container,
  .container {
    margin: 0 !important;
    padding: 50px 20px 20px 20px !important;
  }
}
