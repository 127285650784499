.Todays-app-section {
  padding: 20px 0 50px 0;
}
.todays-outer {
  padding-bottom: 20px;
}
.todays-inner-wrapper {
  border: 1px #dbd8d8 solid;
  padding: 20px;
  border-radius: 10px;
  margin: 5px 0px;
}

.todays-inner-wrapper p {
  margin: 0;
  padding: 3px 0;
  font-size: 15px;
  font-weight: 500;
}

.todays-accept-btn {
  display: flex;
  margin-top: 20px;
}

.todays-accept-btn .accept-btn,
.todays-decline-btn .decline-btn {
  display: flex;
  align-items: center;
  border-radius: 3px;
  border: 0;

  color: #fff;
  padding: 5px 15px;
}
.todays-accept-btn .accept-btn {
  background-color: green;
}
.todays-accept-btn .accept-btn p,
.todays-decline-btn .decline-btn p {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  padding-left: 5px;
}

.todays-decline-btn {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.todays-decline-btn .decline-btn {
  background-color: #d51f29;
}

.conference-select select {
  border: 1px #dbd8d8 solid;
  border-radius: 5px;
  width: 100%;
  padding: 5px;
}
.date-time-div {
  display: grid;
}
/* .todays-done-btn,
.todays-cancel-btn {
 
  justify-content: end;
  margin-top: 10px;
} */
.todays-appt-btn-wrapper {
  display: flex;
}
.todays-decline-btn {
  margin-left: 10px;
}
.todays-done-btn .done-btn {
  display: flex;
  align-items: center;
  border: 0;
  color: rgb(0, 153, 255);
  background-color: #fff;
}

.todays-done-btn .done-btn p {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
}

.todays-cancel-btn .cancel-btn {
  display: flex;
  align-items: center;
  border: 0;
  color: #d51f29;
  background-color: #fff;
}

.todays-cancel-btn .cancel-btn p {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
}

.conference-select lable {
  font-size: 15px;
}

.conference-select select {
  font-size: 15px;
}
.appointment-search-btn {
  padding-top: 30px;
}
.appointment-search-btn button {
  padding: 10px 30px;
  border: none;
  color: #fff;
  border-radius: 5px;
}
.todays-inner-wrapper span,
.conference-select label {
  font-weight: 600;
  padding-right: 5px;
}
.date-time-div {
  display: grid;
}
