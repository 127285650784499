.Sidebar_Container {
  background-color: #fff;
  /* border-radius: 8px; */
  /* height: 547px; */
  padding: 10px;
}

.Sidebar_Top_Div {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  padding-top: 13px;
  align-items: center;
}

.Sidebar_TopLogo {
  width: 200px;
}

.Logo_Text {
  padding-top: 5px;
  font-size: 15px;
  font-weight: bold;
}

.Sidebar_Nav_Div {
  padding-top: 10px;
}

.Sidebar_Nav {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  margin: 10px;
  padding: 4px;
  border-radius: 8px;
  align-items: center !important;
  cursor: pointer;
  text-decoration: none !important;
}

.Sidebar_Nav:hover {
  background-color: #ccc;
}

.active {
  display: flex;
  flex-direction: row;
  background-color: red;
  margin: 10px;
  padding: 4px;
  border-radius: 8px;
  align-items: center !important;
  cursor: pointer;
  text-decoration: none !important;
}

.active h6 {
  text-align: center;
  padding-top: 7px;
  color: #fff;
}

.active Sidebar_Nav_Icon {
  font-size: 21px;
  color: #fff;
  margin-left: 15px;
  margin-right: 15px;
}

.Sidebar_Nav_Icon {
  font-size: 21px;
  color: #000;
  margin-left: 15px;
  margin-right: 15px;
}

.activeIcon {
  font-size: 21px;
  color: #fff;
  margin-left: 15px;
  margin-right: 15px;
}

.Sidebar_Nav h6 {
  text-align: center;
  padding-top: 7px;
  color: #000;
}

.Sidebar_Container p {
  padding-top: 70px;
  text-align: center;
  font-size: 14px;
  align-self: flex-end;
}

.Sidebar_Line {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1.5px solid #ccc;
  margin: 10px;
  padding: 0;
}

.Logout_Main_div {
  padding: 30px;
}

.Logout_Main_div h5 {
  text-align: center;
  color: red;
}

.Logout_Main_div p {
  text-align: center;
  font-size: 15px;
  padding-top: 12px;
}

.Logout_Button {
  display: flex;
  justify-content: center;
  align-content: center;
}

.button-28 {
  appearance: none;
  background-color: transparent;
  border: 1px solid #1a1a1a;
  border-radius: 7px;
  box-sizing: border-box;
  color: #3b3b3b;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  min-height: 45px;
  width: 150px;
  margin: 10px;
  outline: none;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
}

.button-28:disabled {
  pointer-events: none;
}

.button-28:hover {
  color: #fff;
  background-color: red;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.button-28:active {
  box-shadow: none;
  transform: translateY(0);
}
.menu-btn {
  display: none;
}
/* 
.offcanvas {
  z-index: 9999;
}

.offcanvas-top {
  height: auto;
}

.offcanvas-header {
  padding: 20px;
}
.top-contact-icon {
  color: #fff;
}
.offcanvas-body {
  padding: 30px 25px;
  background-color: #000249;
}

.offcanvas-menu-list {
  color: #fff;
  list-style: none;
}
.offcanvas-menu-list li {
  padding: 10px 0;
  border-bottom: 1px solid #ffffff5c;
}
.offcanvas-menu-list li a {
  color: #fff;
  padding: 10px 0;
  text-decoration: none;
  font-weight: 500;
  font-size: 15px;
}
.offcanvas-planner {
  width: 150px !important;
}

.offcanvas-social-icon-section,
.offcanvas-header-list-section ul li {
  padding-top: 20px;
}
.offcanvas-social-icon-section ul {
  display: flex;
  list-style: none;
  align-items: center;
}

.offcanvas-social-icon-section ul li {
  padding-right: 20px;
}

.offcanvas-header-list-section li {
  display: flex;
  list-style: none;

  align-items: center;
}
.offcanvas-header-list-section p {
  padding-left: 10px;
  color: #fff;
  letter-spacing: 0.2px;
} */

@media (max-width: 992px) {
  .sidebar-mb-hide,
  .sidebar-mb-hide,
  .Side_Bar {
    display: none;
  }

  .mb-nav-Bar,
  .Layout,
  .menu-btn {
    display: block;
  }
}

@media (max-width: 576px) {
}
