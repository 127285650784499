.custReg-section {
  width: 100%;

  background-image: url("../Assets/IMages/loginbg.jpg");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 150px 50px;
  height: 100vh;
}
.cust-reg-bg-wrapper {
  padding: 25px 35px 35px 35px;

  border-radius: 10px;
  margin: auto;
  width: 50%;
}

.custReg-title {
  padding: 15px;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  letter-spacing: 0.3px;
}
.custReg-logo {
  display: flex;
  justify-content: center;
  display: none;
}
.custReg-form {
  color: #fff;
}
.input-div {
  padding-bottom: 10px;
}
.custReg-form label {
  display: block;
  margin-bottom: 5px;
  letter-spacing: 0.2px;
}

.custReg-form input {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: none;
  font-size: 15px;
  padding: 10px;
}

.custReg-form input:focus {
  outline: none;
}
.custReg-form input::placeholder {
  color: #aaa9a9;
  font-size: 15px;
}

.btn--form {
  background-color: #d21404;
  color: #fdf2e9;
  margin-top: 15px;
}
.forgot-pwd-btn {
  padding-top: 10px;
  color: #fff;
  font-size: 15px;
  letter-spacing: 0.2px;
}

.cust-reg-bg-wrapper .btn,
.cust-reg-bg-wrapper .btn:link,
.cust-reg-bg-wrapper .btn:visited {
  display: inline-block;
  text-decoration: none;
  font-size: 17px;
  border-radius: 3px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s;
  margin: 5px 10px;
  letter-spacing: 0.3px;
}
.cust-reg-bg-wrapper .btn-div {
  display: flex;
  justify-content: end;
}

.btn--form:hover {
  background-color: #d62b1d;
}

.custReg-section h3 {
  text-align: center;

  color: #fff;
}
.cust-reg-title {
  position: relative;
}
.cust-reg-title::after {
  position: absolute;
  content: "";
  bottom: -5px;
  left: 10%;
  background-color: #dd1717;
  height: 2px;
  width: 80%;
}

.logo-img {
  width: 200px;
  padding: 5px;
  background: #fff;
  border-radius: 3px;
}

.Base_Line {
  color: #fff;
  text-align: end;
  font-size: 14px;
  padding-top: 15px;
  padding-right: 50px;
  cursor: pointer;
}

.chng-pwd-title {
  color: #fff;
  padding-bottom: 20px;
  padding-top: 20px;
  text-align: center;
}
.req-errors {
  color: #d21404;
  font-size: 14px;
  margin-bottom: 0px;
}
.bottom-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cust-login-liink-div p {
  margin-bottom: 0px;
  font-size: 17px;
}

.cust-login-liink-div a {
  color: #fbf537;
}

@media  (min-width: 767px) and (max-width: 1200px)  {
  .custReg-section {
    padding: 30px 10px;
  }
  .cust-reg-bg-wrapper {
    padding: 25px 0px 35px 0px;
    border-radius: 10px;
    margin: auto;
    width: 100%;
  }
  .custReg-section {
    height: auto;
  }
  .bottom-section {
    display: block;
  }
  .cust-reg-bg-wrapper .btn-div {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
  }
  .custReg-title {
    padding: 0px;
    font-size: 27px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .login-form input {
    width: 50%;
  }
}
@media (max-width: 768px) {
  .login-form input {
    width: 100%;
  }
  .custReg-section {
    padding: 30px 10px;
  }
  .cust-reg-bg-wrapper {
    padding: 25px 0px 35px 0px;
    border-radius: 10px;
    margin: auto;
    width: 100%;
  }
  .custReg-section {
    height: auto;
  }
  .bottom-section {
    display: block;
  }
  .cust-reg-bg-wrapper .btn-div {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
  }
  .custReg-title {
    padding: 0px;
    font-size: 27px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
