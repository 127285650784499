.Dashboard_Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 14px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
  margin-top: 5px;
}

.Dashboard_Header h5 {
  padding-top: 4px;
}

.Align_Row {
  display: flex;
  flex-direction: row;
}

.Dashborad_SubHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}
.appointment-btn-top-wrapper {
  display: flex;
  align-items: center;
}
.Dashborad_SubHeader h5 {
  text-align: center !important;
  padding-top: 4px;
}

.make_Button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.make_Button p {
  color: #fff;
  /* font-size: 14px; */
  text-align: center;

  padding-left: 5px;
  margin-bottom: 0px;
}
.form-control:focus,
.form-select:focus {
  box-shadow: none;
}
/* Calendar */

.calendar-appointment {
  margin: 30px 0;
}

/* .dashboard-wrapper{
    padding: 40px 15px;
} */

.react-calendar {
  padding-bottom: 10px;
}

.react-calendar__navigation {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 20px 0;
  background-color: #dbd8d8;
  width: 100%;
  height: auto !important;
}

.react-calendar__tile {
  padding: 10px;
}

.react-calendar__navigation__label,
.react-calendar__navigation__arrow,
.react-calendar__navigation__prev2-button,
.react-calendar__month-view__days,
.react-calendar__tile {
  border: none;
  background-color: #fff;
}

.react-calendar {
  /* width: 100% !important; */
  max-width: 100%;
  background: white;
  border: 1px solid #dbd8d8 !important;
  border-radius: 5px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  /* padding: 20px; */
}
.dashboard-calender {
  padding: 50px;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  padding: 10px 0;
}

.react-calendar__tile {
  padding: 12px;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration-skip-ink: none;
  text-decoration: none;
  text-align: center;
  font-weight: 600;
}

abbr {
  font-size: 15px;
}

.react-calendar__tile--active {
  background: #180c3c !important;
  color: white;
}

.upcoming-wrapper {
  border: 1px #dbd8d8 solid;
  border-radius: 5px;
  /* box-shadow: 2px 2px 4px 0 rgb(2 45 98/10%); */
}

.upcoming-wrapper h5 {
  text-align: center;
  background-color: #dbd8d8;
  padding: 10px;
}

.upcoming-app {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid #dbd8d8;
}

.upcoming-app p {
  font-size: 15px;
  color: #575757;
  margin: 0;
}

.content {
  font-size: 20px;
  color: 180c3c;
}

/* Modal */

.model-sub-heading {
  text-align: left;
  color: #180c3c;
  margin-bottom: 20px;
}

.input-book {
  width: 100%;
  border: 0.5px #dbd8d8 solid;
  border-radius: 3px;
  padding: 3px;
  font-size: 15px;
}

.input-book:focus {
  border: 0.5px #180c3c solid !important;
}

.book-label {
  font-size: 15px;
  color: #575757;
}

.model-close-btn {
  background-color: red;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  padding: 9px;
}

.model-book-btn {
  background-color: #000249;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  padding: 9px;
}

.Model-div-reset {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Reset-Button {
  cursor: pointer;
}

.card-icon {
  color: #000249;
  position: absolute;
  top: 0;
  bottom: 0;
  inset: 0;
  margin: auto;
}
.card-icon-bg {
  background: rgb(13 110 253 / 9%);
  width: 60px;
  height: 60px;
  border-radius: 100%;
  padding: 5px;
  position: relative;
}
.dashboard-card-wrapper {
  background: #fff;
  padding: 20px 10px 10px 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 1px #dbd8d8 solid; */
}
.dashboard-card-col {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 150px;
  position: relative;
}
.view-deatils {
  background: red;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 10px 20px;
  color: #fff;
  position: absolute;
    width: 100%;
    bottom: 0;
}
.notification-section {
  display: flex;
  position: relative;
}
.notification-icon {
  color: #000249;
}
.notification-cnt {
  background: #dd1717;
  padding: 5px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: block;
  color: #fff;
  position: absolute;
  top: 10px;
  left: 30px;
}
.notification-cnt b {
  color: #fff;
}
.dashboard-section {
  padding: 30px 0px;
}
.dashboard-section a {
  text-decoration: none;
}
.dashboard-card-name {
  text-decoration: none;
  color: #000249;
}
.dashboard-count {
  color: #007bff;
  font-size: 20px;
  font-weight: 600;
  padding: 2px;
}
.session-title {
  font-size: 28px;
  font-weight: 600;
  padding: 10px 0px 20px 12px;
  color: #000249;
}
.notify-greeting-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 25px;
}
.notify-greeting-section h3 {
  color: #000249;
}
.react-calendar .highlight {
  background-color: #007bff;
  color: #fff;
}
/* table css */
.tbl-search-box {
  display: flex;
  justify-content: end;
}
.appointment-details-title {
  display: flex;
  padding: 20px 12px 10px 12px;
  position: relative;
  color: #000249;
}
.booked-date {
  padding-left: 5px;
}

.appointment-details-title::after {
  border-bottom: 2px solid #dd1717;
  position: absolute;
  content: "";
  height: 2px;
  left: 13px;
  bottom: 10px;
  width: 10%;
}

.appointment-details {
  padding: 10px 0px;
}
.appointment-details p {
  margin-bottom: 0px;
}
.appointment-details-inner {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px;
  border-radius: 5px;
}
.appt-status {
  color: #198754;
}
.bg-todays {
  background-color: #fb8500;
}
.bg-decline {
  background-color: #064789;
}
.bg-complete {
  background-color: #004b23;
}
.bg-pending {
  background-color: #d90429;
}
@media (max-width: 576px) {
  .tbl-search-box,
  .tbl-pagination {
    justify-content: start !important;
  }
  .Dashborad_SubHeader,
  .notify-greeting-section {
    display: block;
  }
  .notify-greeting-section h3 {
    text-align: center;
  }
 
}
@media (max-width: 992px) {
}

@media (max-width: 992px) {
  .calender-make-btn-row {
    flex-direction: column-reverse;
  }
  .Dashborad_SubHeader,
  .dashboard-calender {
    padding: 20px;
  }
}

