
/* Profile */
.profile-bg-img {
    height: 300px;
    width: 100%;
    background-image: url("../Assets/IMages/ProfileBgImg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
  }
  .img-inner-section {
    padding: 40px 30px;
    width: 50%;
  }
  .pro-welcome {
    font-size: 18px;
    color: white;
    margin: 0px;
  }
  .pro-title {
    color: white;
    font-size: 22px;
    margin: 0px;
    padding-top: 2px;
  }
  .pro-text {
    padding-top: 12px;
    color: white;
    font-size: 14px;
  }
  .pro-btn {
    color: white;
    font-size: 16px;
    background-color: red;
    outline: none;
    border: none;
    border-radius: 8px;
    margin-top: 5px;
    padding: 7px 20px;
    cursor: pointer;
  }
  .pro-lower-section {
    padding: 30px 0px;
  }
  .pro-lower-title {
    color: black;
    font-size: 17px;
    font-weight: 600;
  }
  .status-section {
    border: 1px #dbd8d8 solid;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .status-section .bi {
   
    color: #007bff !important;
    
  }
  .pro-count {
    color: #007bff;
    font-size: 20px;
    font-weight: 600;
    padding: 2px;
  }
  /* .pro-status-name {
    color: black;
    font-size: 16px;
   
  } */
  .pro-meet-table {
    border-radius: 8px;
    box-shadow: 1px 2px 3px #e6e6e6;
    border: 1px #dbd8d8 solid;
  }
  .pro-sch-table {
    width: 100%;
    
  }
  .meet-heading {
    border-bottom: 2px #007bff solid;
  }
  .meet-heading th {
    font-size: 15px;
    padding: 12px;
    text-align: center;

  }
  .meet-data td {
    padding: 12px;
    font-size: 14px;
    text-align: center;

  }
  .pro-meet-table h6{
    text-align: end;
    font-size: 15px;
    font-weight: 600;
    color: #007bff;
    padding: 15px;
    cursor: pointer;
   

  }