.Main_Applayout {
  min-height: 100vh;
  background-color: #ffff;
}

.Layout {
  display: flex;
}

.Side_Bar {
  min-height: 100vh;
  width: 340px;
  background: #ffff;
}

.App_Contant {
  width: 100%;
  height: 100%;
}

.App_Body {
  background-color: #d9e3ee;
  height: 100vh;
  overflow-y: scroll;
  padding: 10px;
}
.mb-nav-Bar {
  display: none;
}
.mb-header-wrapper {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  justify-content: space-between;
}
.header_TopLogo {
  width: 200px;
}
.offcanvas {
  z-index: 9999;
}

.offcanvas-top {
  height: auto;
}

.offcanvas-header {
  padding: 20px;
}
.top-contact-icon {
  color: #fff;
}
.offcanvas-body {
  padding: 30px 25px;
  background-color: #000249;
 
}

.offcanvas-menu-list {
  color: #fff;
  list-style: none;
}
.offcanvas-menu-list li {
  padding: 10px 0;
  border-bottom: 1px solid #ffffff5c;
}
.offcanvas-menu-list li a {
  color: #fff;
  padding: 10px 0;
  text-decoration: none;
  font-weight: 500;
  font-size: 15px;
}
.offcanvas-planner {
  width: 150px !important;
}

.offcanvas-social-icon-section,
.offcanvas-header-list-section ul li {
  padding-top: 20px;
}
.offcanvas-social-icon-section ul {
  display: flex;
  list-style: none;
  align-items: center;
}

.offcanvas-social-icon-section ul li {
  padding-right: 20px;
}

.offcanvas-header-list-section li {
  display: flex;
  list-style: none;

  align-items: center;
}
.offcanvas-header-list-section p {
  padding-left: 10px;
  color: #fff;
  letter-spacing: 0.2px;
}


@media (max-width: 768px) {

}


@media (max-width: 576px) {
 
}
